// Dependencies - Vendor
import { defineStore } from 'pinia';
import { ref } from 'vue';

// Dependencies - Framework
import { LOCAL_STORAGE } from '@/globals';

// Dependencies - Components
import type { ToolPanelId } from '@/views/toolMenu/ToolTabs.vue';

// Interfaces/Types - Drawer State Identifier
export type DrawerStateId = 'collapsed' | 'expanded' | 'floating';

// Interfaces/Types - Width Identifier
export type WidthId = 'narrow' | 'wide'; // The width of the the drawer or window when it is displayed. This value is not updated for drawers when they are collapsed or floating.

// Constants
const DEFAULT_DELAY = 200;
const DRAWER_MAXIMUM_WIDTH = 641;
const DRAWER_MEDIAN_WIDTH = 321;
const OPTION_DRAWER_MEDIAN_WIDTH = 251;
export const DRAWER_MINIMUM_WIDTH = 65;
const WINDOW_BREAK_POINT = 1280;

// Workbench Store - Compose & Expose
export const useWorkbenchStore = defineStore('workbench', () => {
    // Utilities - Get Drawer State Identifier
    const getDrawerStateId = (localStorageId: string): DrawerStateId => (window.localStorage.getItem(localStorageId) === 'expanded' ? 'expanded' : 'collapsed');

    // Utilities - Get Drawer Width Identifier
    const getDrawerWidthId = (localStorageId: string): WidthId => (window.localStorage.getItem(localStorageId) === 'wide' ? 'wide' : 'narrow');

    // Utilities - Toggle Option Drawer
    const setToolDrawerWidth = (toolDrawerStateId: DrawerStateId, toolDrawerWidthId: WidthId): number => {
        if (toolDrawerStateId === 'collapsed') return 0;
        switch (toolDrawerWidthId) {
            case 'narrow':
                return DRAWER_MEDIAN_WIDTH;
            case 'wide':
                return DRAWER_MAXIMUM_WIDTH;
            default:
                return 0;
        }
    };

    // Properties & Variables
    const breadcrumbs = ref<string[]>([]);
    const isEUInstance = ref<boolean | undefined>(undefined);
    const isLoading = ref<boolean>(false);
    const lastDeployedVersion = ref<string | undefined>(undefined);
    const maskIsActive = ref<boolean>(false);
    let maskTimeout: number | undefined = window.setTimeout((): boolean => (isLoading.value = true), DEFAULT_DELAY);
    const moduleIsLoading = ref<boolean>(false);
    const navigationDepth = ref<number>(0);
    const title = ref<string | undefined>(undefined);
    const windowWidth = ref<number>(0);
    const windowWidthId = ref<WidthId>('wide');

    // MASK: Methods - Clear Mask Timeout
    const clearMaskTimeout = (): void => {
        window.clearTimeout(maskTimeout);
        isLoading.value = false;
        maskTimeout = undefined;
    };

    // OPTION DRAWER: Properties & Variables
    const initialOptionDrawerWidthId = getDrawerWidthId(LOCAL_STORAGE.OPTION_DRAWER.WIDTH_ID);
    const initialOptionDrawerWidth = initialOptionDrawerWidthId === 'narrow' ? DRAWER_MINIMUM_WIDTH : OPTION_DRAWER_MEDIAN_WIDTH;
    const optionDrawerPanelId = ref<string | undefined>(undefined);
    const optionDrawerStateId = ref<DrawerStateId>('expanded');
    const optionDrawerWidth = ref<number>(initialOptionDrawerWidth);
    const optionDrawerWidthId = ref<WidthId>(initialOptionDrawerWidthId);
    const optionDrawerMenuWidth = ref<number>(initialOptionDrawerWidth);

    // OPTION DRAWER: Methods - Collapse Option Drawer
    const collapseOptionDrawer = (): void => {
        optionDrawerWidth.value = 0;
        optionDrawerMenuWidth.value = DRAWER_MINIMUM_WIDTH;
        optionDrawerStateId.value = 'collapsed';
    };

    // OPTION DRAWER: Methods - Float Option Drawer
    const floatOptionDrawer = (): void => {
        optionDrawerWidth.value = OPTION_DRAWER_MEDIAN_WIDTH;
        optionDrawerMenuWidth.value = OPTION_DRAWER_MEDIAN_WIDTH;
        optionDrawerStateId.value = 'floating';
    };

    // OPTION DRAWER: Methods - Toggle Option Drawer
    const toggleOptionDrawer = (): void => {
        if (windowWidth.value >= WINDOW_BREAK_POINT) {
            optionDrawerStateId.value = 'expanded';
            optionDrawerWidthId.value = optionDrawerWidthId.value === 'narrow' ? 'wide' : 'narrow';
            optionDrawerWidth.value = optionDrawerWidthId.value === 'narrow' ? DRAWER_MINIMUM_WIDTH : OPTION_DRAWER_MEDIAN_WIDTH;
            optionDrawerMenuWidth.value = optionDrawerWidth.value;
            window.localStorage.setItem(LOCAL_STORAGE.OPTION_DRAWER.WIDTH_ID, optionDrawerWidthId.value);
            return;
        }
        if (optionDrawerStateId.value === 'collapsed') {
            floatOptionDrawer();
            return;
        }
        collapseOptionDrawer();
    };

    // TOOL COMPONENTS: Properties & Variables
    const initialToolDrawerStateId = getDrawerStateId(LOCAL_STORAGE.TOOL_DRAWER.STATE_ID);
    const initialToolDrawerWidthId = getDrawerWidthId(LOCAL_STORAGE.TOOL_DRAWER.WIDTH_ID);
    const toolDrawerPanelId = ref<string | undefined>(window.localStorage.getItem(LOCAL_STORAGE.TOOL_DRAWER.PANEL_ID) || undefined);
    const toolDrawerStateId = ref<DrawerStateId>(getDrawerStateId(LOCAL_STORAGE.TOOL_DRAWER.STATE_ID));
    const toolDrawerWidth = ref<number>(setToolDrawerWidth(initialToolDrawerStateId, initialToolDrawerWidthId));
    const toolDrawerWidthId = ref<WidthId>(initialToolDrawerWidthId);
    const toolMenuDataActionsEnabled = ref<boolean>(false);
    const toolMenuDropdownIsOpen = ref<boolean>(false);
    const toolMenuWidth = ref<number>(DRAWER_MINIMUM_WIDTH);

    // TOOL COMPONENTS: Methods - Collapse Tool Components
    const collapseToolComponents = (): void => {
        toolMenuDropdownIsOpen.value = false;
        toolDrawerPanelId.value = undefined;
        toolDrawerStateId.value = 'collapsed';
        toolDrawerWidth.value = 0;
        toolMenuWidth.value = DRAWER_MINIMUM_WIDTH;
    };

    // TOOL COMPONENTS: Methods - Enable Tool Components Menu Data Actions
    const enableToolComponentsMenuDataActions = (enabled: boolean): void => {
        navigationDepth.value = 0;
        toolMenuDataActionsEnabled.value = enabled;
        // HideWorkbenchMask(); // TODO
    };

    // TOOL COMPONENTS: Methods - Handle Tool Components Menu Tab Change
    const handleToolComponentsMenuTabChange = (toolPanelId?: ToolPanelId): void => {
        if (windowWidth.value >= WINDOW_BREAK_POINT) {
            if (toolPanelId === undefined || toolDrawerPanelId.value === toolPanelId) {
                window.localStorage.setItem(LOCAL_STORAGE.TOOL_DRAWER.PANEL_ID, (toolDrawerPanelId.value = undefined) || '');
                window.localStorage.setItem(LOCAL_STORAGE.TOOL_DRAWER.STATE_ID, (toolDrawerStateId.value = 'collapsed'));
                toolDrawerWidth.value = setToolDrawerWidth(toolDrawerStateId.value, toolDrawerWidthId.value);
                return;
            }
            window.localStorage.setItem(LOCAL_STORAGE.TOOL_DRAWER.PANEL_ID, (toolDrawerPanelId.value = toolPanelId));
            window.localStorage.setItem(LOCAL_STORAGE.TOOL_DRAWER.STATE_ID, (toolDrawerStateId.value = 'expanded'));
            toolDrawerWidth.value = setToolDrawerWidth(toolDrawerStateId.value, toolDrawerWidthId.value);
            return;
        }
        if (toolPanelId === undefined) {
            collapseToolComponents();
            window.localStorage.setItem(LOCAL_STORAGE.TOOL_DRAWER.PANEL_ID, (toolDrawerPanelId.value = undefined) || '');
            window.localStorage.setItem(LOCAL_STORAGE.TOOL_DRAWER.STATE_ID, toolDrawerStateId.value);
            toolDrawerWidth.value = setToolDrawerWidth(toolDrawerStateId.value, toolDrawerWidthId.value);
            return;
        }
        toolDrawerStateId.value = 'floating';
        window.localStorage.setItem(LOCAL_STORAGE.TOOL_DRAWER.PANEL_ID, (toolDrawerPanelId.value = toolPanelId));
        window.localStorage.setItem(LOCAL_STORAGE.TOOL_DRAWER.STATE_ID, 'expanded');
        toolDrawerWidth.value = setToolDrawerWidth(toolDrawerStateId.value, toolDrawerWidthId.value);
    };

    // TOOL COMPONENTS: Methods - Resize Tool Components
    const resizeToolComponents = (): void => {
        toolDrawerWidthId.value = toolDrawerWidthId.value === 'narrow' ? 'wide' : 'narrow';
        // toolDrawerWidth.value = toolDrawerWidthId.value === 'narrow' ? DRAWER_MEDIAN_WIDTH : DRAWER_MAXIMUM_WIDTH;
        toolDrawerWidth.value = setToolDrawerWidth(toolDrawerStateId.value, toolDrawerWidthId.value);
        localStorage.setItem(LOCAL_STORAGE.TOOL_DRAWER.WIDTH_ID, toolDrawerWidthId.value);
    };

    // TOOL COMPONENTS: Methods - Toggle Tool Components
    const toggleToolComponents = (): void => {
        if (toolMenuDropdownIsOpen.value || toolDrawerPanelId.value) {
            toolMenuDropdownIsOpen.value = false;
            toolMenuWidth.value = DRAWER_MINIMUM_WIDTH;
            handleToolComponentsMenuTabChange();
            return;
        }
        toolDrawerStateId.value = 'floating';
        toolMenuDropdownIsOpen.value = true;
        toolMenuWidth.value = DRAWER_MEDIAN_WIDTH;
    };

    // WINDOW: Methods - Handle Window Width Change
    const handleWindowWidthChange = (width: number): void => {
        if (width >= WINDOW_BREAK_POINT) {
            windowWidthId.value = 'wide';
            if (windowWidth.value < WINDOW_BREAK_POINT) {
                // Handle new width  greater than or equal to the breakpoint and prior width less than it.
                optionDrawerStateId.value = 'expanded';
                optionDrawerWidthId.value = getDrawerWidthId(LOCAL_STORAGE.OPTION_DRAWER.WIDTH_ID);
                optionDrawerWidth.value = optionDrawerWidthId.value === 'narrow' ? DRAWER_MINIMUM_WIDTH : OPTION_DRAWER_MEDIAN_WIDTH;
                optionDrawerMenuWidth.value = optionDrawerWidth.value;
                if (toolDrawerStateId.value === 'floating') {
                    toolDrawerStateId.value = 'expanded';
                }
                toolDrawerWidthId.value = getDrawerWidthId(LOCAL_STORAGE.TOOL_DRAWER.WIDTH_ID);
                toolDrawerWidth.value = setToolDrawerWidth(toolDrawerStateId.value, toolDrawerWidthId.value);
                toolMenuDropdownIsOpen.value = false;
                toolMenuWidth.value = DRAWER_MINIMUM_WIDTH;
            }
            windowWidth.value = width;
            return;
        }
        windowWidthId.value = 'narrow';
        if (windowWidth.value === 0 || windowWidth.value >= WINDOW_BREAK_POINT) {
            // Handle prior width unset or new width less than the breakpoint and prior width greater than or equal to it.
            collapseOptionDrawer();
            toolDrawerStateId.value = getDrawerStateId(LOCAL_STORAGE.TOOL_DRAWER.STATE_ID);
            if (toolDrawerStateId.value === 'expanded') {
                toolDrawerStateId.value = 'floating';
                toolDrawerWidthId.value = 'narrow';
                toolDrawerWidth.value = setToolDrawerWidth(toolDrawerStateId.value, toolDrawerWidthId.value);
            }
            windowWidth.value = width;
            return;
        }
        // ...
        toolDrawerWidthId.value = 'narrow';
        windowWidth.value = width;
    };

    // Exposures
    return {
        breadcrumbs,
        isEUInstance,
        isLoading,
        lastDeployedVersion,
        maskIsActive,
        moduleIsLoading,
        navigationDepth,
        optionDrawerPanelId,
        optionDrawerStateId,
        optionDrawerMenuWidth,
        optionDrawerWidthId,
        title,
        toolDrawerPanelId,
        toolDrawerStateId,
        toolDrawerWidth,
        toolDrawerWidthId,
        toolMenuDataActionsEnabled,
        toolMenuDropdownIsOpen,
        toolMenuWidth,
        windowWidthId,
        clearMaskTimeout,
        collapseOptionDrawer,
        collapseToolComponents,
        enableToolComponentsMenuDataActions,
        handleToolComponentsMenuTabChange,
        handleWindowWidthChange,
        resizeToolComponents,
        toggleOptionDrawer,
        toggleToolComponents
    };
});
